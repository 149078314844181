.underbar {
    position: relative;
}

.underbar:after {
  -webkit-animation: underline 3s;
  animation: underline 3s;
  content: '';
  width: 80px;
  height: 4px;
  background: rgb(255, 0, 0);
  position: absolute;
  bottom: -4px;
}

@-webkit-keyframes underline {
  from {
    width: 0px;
  }
  to {
    width: 80px;
  }
}

@keyframes underline {
  from {
    width: 0px;
  }
  to {
    width: 80px;
  }
}