.navbar {
  z-index: 9;
  max-width: 100%;
  background: #171717;
  top: -10px;
}

@media only screen and (min-width: 1200px)
{
#basic-navbar-nav {
  flex-basis: content;
}
}

.navbar-toggler {
  position: relative;
  color: red!important;
  border-color: red!important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 0, 0, 1.0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")!important;
}

.nav-link {
  color:white!important;
  padding-top: 2px;
}

@media only screen and (max-width: 1199px) {
.navbar-collapse {
  background: rgba(22, 22, 22, 0.8);
  flex-basis: content;
}
}

@media only screen and (min-width: 1200px) {

a.nav-link:after {
  content: '';
  display: block;
  border-bottom: 3px solid red;
  width: 0;
  position: relative;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

a.nav-link:hover:after { 
  width: 100%; 
}

}

@media only screen and (min-width: 576px) and (max-width: 1199px) {
.navbar-brand b {
    font-size: 2rem!important;
}
}

.navbar-brand b {
  font-size: 1.5rem;
  color:white!important;
  flex-basis: content;
}

.navbar-brand .accent {
  display: inline;
  color: #ff0000;
  font-size: larger;
}

.popover-body {
  width: 100%;
  background: rgba(255, 255, 255, 0.795);
  font-weight: 600!important;
  color: black!important;
  font-family: 'Roboto' serif;
}