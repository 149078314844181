
.hero {
  position: relative!important;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  max-height: -webkit-fill-available;
}

.hero img {
  height: 100vh;
  height: -webkit-fill-available;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  max-height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  object-position: center;
  opacity: 0.7;
}

/* Fix viewport size adjust on scroll on mobile */
@media only screen and (max-width: 768px) {
  .hero {
      height: calc(var(--vh, 1vh) * 100);
      max-height: calc(var(--vh, 1vh) * 100);
  }

  .hero img {
    height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
  }
}

@media only screen and (max-width: 768px) and (orientation:landscape) {
  .hero {
      height: calc(var(--vh, 1vh) * 100);
      top: 10vh;
      max-height: calc(var(--vh, 1vh) * 100);
  }

  .hero img {
    height: calc(var(--vh, 1vh) * 100);
    top: 10vh;
    max-height: calc(var(--vh, 1vh) * 100);
  }
}

.slogan .accent-dim {
  color: lightgray;
  display: inline;
}

.slogan {
    position: absolute!important;
    color: white;
    z-index: 999;
    display: flex;
    bottom: 100px;
    height: 100%;
    width: 100%;
}

@media only screen and (min-width: 992px) {
  .slogan {
    bottom: 200px;
  }
}

.slogan-header:after {
  -webkit-animation: underline 3s;
  animation: underline 3s;
  content: '';
  width: 80px;
  height: 4px;
  background: rgb(255, 0, 0);
  position: absolute;
  bottom: -4px;
}

@-webkit-keyframes underline {
  from {
    width: 0px;
  }
  to {
    width: 80px;
  }
}

@keyframes underline {
  from {
    width: 0px;
  }
  to {
    width: 80px;
  }
}

.slogan-textblock h2 {
  padding-bottom: 10px;
  padding-top: 30px;
  color: rgb(211, 211, 211);
  font-size: 1.5rem;
  font-weight: 300;
  font-family: "metropolis-light", sans-serif;
}

@media only screen and (min-width: 1199px) and (max-width: 1500px) {
  .slogan h1 {
      font-size: 3rem;
  }

  .slogan-textblock h2 {
    font-size: 2rem;
    padding-top: 50px;
    font-weight: 300;
  }
}

@media only screen and (min-width: 1500px) {
  .slogan h1 {
      font-size: 4rem;
  }

  .slogan-textblock h2 {
    font-size: 2.8rem;
    padding-top: 50px;
    font-weight: 300;
  }
}

.slogan .pulse {
  /*animation: pulse 4s;
  -webkit-animation: pulse 4s;*/
  font-weight: bolder;
  display: inline;
}

@-webkit-keyframes pulse {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}