.fab {
    font-size: 2rem;
    color: white!important;
    padding-right: 30px;
    margin-bottom: 20px!important;
}

ul.contact  {
    list-style: none;
    padding-left: 0!important;
}