.insta {
    height: auto;
}

.insta img {
    object-fit: cover;
    width: 100%;
    height: 40vh;
}

.insta img:hover {
    opacity: 0.5;
}

.insta .card-wrapper {
    height: auto;
    background: black;
    margin-bottom: 4vw;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.603);
}

.insta .card-wrapper > .description {
    visibility: hidden;
    height: 0;
    margin: 0;
}

.insta .img-fluid {
    display: flex!important;
}

.insta .card-wrapper:hover > .description {
    visibility: visible;
    position: absolute;
    word-break: normal;
    cursor: pointer;
    color: white;
    font-weight: 800;
    font-size: 1.5rem;
    height: 100%;
    top: 5vh!important;
    padding: 5vh;
}

@media only screen and (max-width: 768px) {
    .insta .card-wrapper:hover > .description {
        font-weight: 500;
        font-size: 0.9rem;
        height: 100%;
        top: 5vh!important;
        top: calc(var(--vh, 1vh) * 5)!important;
        padding: 5vh;
        padding: calc(var(--vh, 1vh) * 5);
    }
}

.insta .card-wrapper:hover > img {
    opacity: 0.4;
}

@media only screen and (max-width: 767px) {
    .insta img {
        height: calc(var(--vh, 1vh) * 40);
    }
}