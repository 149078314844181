html {
    height:100%;
    width: 100%;
    scroll-behavior: smooth;
}

@keyframes FadeAnim {0% {   opacity: 0;}100% {   opacity: 1;}}

body {
    height: 100%;
    width: 100%;
}

#bodybox {
    background-color: #202020;
}

.hidden {
    visibility: hidden!important;
}

.visible {
    visibility: visible!important;
}

em {
    color: red;
    font-style: normal;   
}

a {
    color: red!important;
}

@media only screen and (max-width: 767px) {
    .head-space {
        padding-top: 12vh;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .head-space {
        padding-top: 9vh;
    }
}

@media only screen and (min-width: 1200px) {
    .head-space {
        padding-top: 3vh;
    }
}

.snap-wrap {
    scroll-snap-align: start;
}

.arrow{
    position: fixed;
    top: 94vh;
    left: 50%;
    transform: translate(-50%,-50%);
}
.arrow span{
    opacity: 0;
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid rgba(255, 0, 0, 0.685);
    border-right: 5px solid #fa111186;
    transform: rotate(45deg);
    margin: -10px;
    animation: downarrow 6s infinite;
    animation-delay: 5s;
}
.arrow span:nth-child(2){
    animation-delay: -0.2s;
}
.arrow span:nth-child(3){
    animation-delay: -0.4s;
}
@keyframes downarrow {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-5px,-5px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(5px,5px);
    }
}