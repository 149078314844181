.over-mij {
    display: relative;
    color: white!important;
    margin-left: 0!important;
    margin-right: 0!important;
    margin-bottom: 17vh;
    margin-top: 7vh;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.384);
}

.over-mij h3 {
    color: rgb(172, 172, 172);
}

.over-mij h2 {
    color: rgb(255, 255, 255);
}

.over-mij p {
    padding-top: 6vh;
    padding-bottom: 8vh;
    position: relative;
    font-family: "metropolis-light", sans-serif;
}

.over-mij .over {
    background-color: rgb(20, 20, 20);
    min-height: 100%;
    max-height: 100%;
    padding: 5vh;
    margin-right: auto;
    position: relative;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.158);
}

@media only screen and (max-width: 767px) {
    .over-mij p {
        padding-top: calc(var(--vh, 1vh) * 6);
        padding-bottom: calc(var(--vh, 1vh) * 8);
    }

    .over-mij {
        margin-bottom: 7vh;
        margin-bottom: calc(var(--vh, 1vh) * 12);
        margin-top: calc(var(--vh, 1vh) * 7);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.116);
    }

    .over-mij .over {
        padding: calc(var(--vh, 1vh) * 5);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .over-mij p {
        padding-top: 5vh;
    }

    .over-mij h3 {
        font-size: 3rem;
    }

    .over-mij h2 {
        font-size: 4rem;
    }
}

@media only screen and (min-width: 1200px) {
    .over-mij h3 {
        font-size: 4rem;
    }

    .over-mij h2 {
        font-size: 5rem;
    }
}

.over-mij .vertical-wrapper {
    margin-bottom: auto;
    height: auto;
}

.over-mij .image-wrapper {
    top: 0vh!important;
    position: relative;
}

.over-mij img {
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.158);
}
