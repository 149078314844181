.hidden-until-animation {
    visibility: hidden;
}

.visible-before-animation {
    visibility: visible;
}

.fadeInBottom { 
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}

.fadeInRightFirst { 
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
}

.fadeInRightSecond { 
    animation-duration: 2.0s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
}

.fadeInRightThird { 
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(300%);
    }
    to { opacity: 1 }
}