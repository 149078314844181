.footer {
    background:black;
    height: auto;
    color:white;
    margin-top: 12vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-family: "metropolis-light", sans-serif;
}

.footer .dev {
    text-align: center;
    display: inline-block;
    width: 100%;
}

.footer ul {
    list-style: none;
}

@media only screen and (max-width: 768px) {
    .footer {
        margin-top: calc(var(--vh, 1vh) * 12);
        padding-top: calc(var(--vh, 1vh) * 5);
        padding-bottom: calc(var(--vh, 1vh) * 5);
        text-align: center;
    }

    .footer ul {
        margin-bottom: 5vh;
        margin-bottom: calc(var(--vh, 1vh) * 5);
    }
}