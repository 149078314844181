/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.wid {
    color: rgb(233, 233, 233);    
    height: auto!important;
    min-height: 100vh;
    height: auto!important;
    padding-bottom: 10vh;
    display: relative;
    padding-top: 8vh;
}

.wid-card h1:before {
  content: '';
  width: 8vw;
  height: 4px;
  background: rgb(255, 0, 0);
  position: absolute;
  margin-top: 5vw;
}

.wid-card .card-wrapper {
    position: relative;
    cursor: pointer;
}

.wid-card .card-wrapper:after {
    content:'';
    position:absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: -o-radial-gradient(rgba(0, 0, 0, 0.1), rgba(20, 20, 20, 0.8));
    background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(20, 20, 20, 0.8));
}

.wid-card img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.3);
}

.wid-card h1 {
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.wid-card:hover {
    -webkit-transform: scale(1.01);
        -ms-transform: scale(1.01);
            transform: scale(1.01);
}

.wid-card .card-wrapper:hover {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.158);
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.158);
}

.wid-card .body {
    top: 0;
    position: absolute;
    width: 0%;
    height: 100%;
    opacity: 1;
    background-color: black;
    font-weight: 500;
    z-index: -1;
    padding-top: 5vh;
    text-align: left;
    font-family: "roboto";
    visibility: hidden;
}

.wid-card.inactive .card-wrapper  {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    opacity: .5;
    -webkit-transform: scale(.98);
        -ms-transform: scale(.98);
            transform: scale(.98);
    -webkit-box-shadow: none;
            box-shadow: none;
    z-index: 1;
}

.wid-card.active {
    z-index: 999;
    -webkit-transform: scale(1.01)!important;
        -ms-transform: scale(1.01)!important;
            transform: scale(1.01)!important;
    -webkit-filter: blur(0px)!important;
            filter: blur(0px)!important;
    opacity: 1!important;

}

.wid-card.active .card-wrapper img {
    z-index: 999;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.wid-card.active .card-wrapper {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.158);
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.158);
}

.wid-card.active .card-wrapper .body {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.377);
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.377);
}

.wid-card.active .body {
    z-index: 0;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    visibility: visible;
}

.wid-card.active {
    margin-left: 0;
    padding-left: 0;
    z-index: 2;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.partner {
    z-index: 10;
    padding-top: 5vh;
    width: 100%;
}

.partner img {
    width: 90%;
    max-height: 100px;
    -o-object-fit: contain!important;
       object-fit: contain!important;
    position: relative;
}

.wid-card.active .body p {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    margin-bottom: 5vh;
    color: rgb(155, 155, 155);
}

.wid-card.active .body h2 {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;

    padding-top: 2vh;
    padding-bottom: 2vh;
    font-weight: 600;
}

.wid-card.active .body .partner {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    padding-bottom: 2vh;
    font-weight: 600;
}

.wid-card .card-wrapper .fa {
    text-align: right;
    font-size: 1.5rem;
    position: absolute;
    width: 100%;
    top: 2vh;
    right: 1vw;
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    .wid-card .card-wrapper {
        height: calc(var(--vh, 1vh) * 70);
    }

    .wid-card .card-wrapper img {
        height: calc(var(--vh, 1vh) * 70);
    }

    .wid-card.active .body h2{
        font-size: 4vw!important;
    }

    .wid-card.active .card-wrapper img {
        max-height: calc(var(--vh, 1vh) * 20);
    }
}

@media only screen and (max-width: 767px) {
    .wid {
        padding-bottom: 2vh;
        padding-bottom: calc(var(--vh, 1vh) * 2);
        max-width: 98vw;
        padding-left: 1vw!important;
    }

    .wid-card {
        text-align: left;
        margin-left: auto!important;
        margin-right: 2vw!important;
    }

    .wid-card .body {
        width: 100%;
        height: auto!important;
        position: absolute;
        padding-bottom: 3vh;
        padding-bottom: calc(var(--vh, 1vh) * 3);
        
    }

    .wid-card .card-wrapper {
        margin-bottom: 30px;
    }

    .wid-card .card-wrapper .fa {
        right: 4vw;
    }

    .wid-card h1 {
        top: 28vh;
        top: calc(var(--vh, 1vh) * 28);
        font-size: 6.5vw;
        font-weight: 700;
        max-width: 80vw;
        margin-left: 4vw;
    }

    .wid-card.active h1 {
        visibility: hidden;
    }

    .wid-card.active .body h2 {
        font-size: 7vw;
    }

    .wid-card.active .card-wrapper {
        visibility: hidden;
    }

    .wid-card .body p {
        margin-bottom: 0px!important;
    }

    .wid-card img {
        max-width: 100%;
        height: 40vh;
        height: calc(var(--vh, 1vh) * 40);
    }

    .wid-card h1:before {
      width: 12vw;
      margin-top: 9vw;
    }

    .wid-card.active {
        height: auto!important;
    }

    .wid-card.active .body {
        height: auto!important;
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .wid-card {
        text-align: left;
    }

    .wid-card .card-wrapper {
        margin-bottom: 40px;
    }

    .wid-card .card-wrapper .fa {
        right: 3vw;
    }

    .wid-card .body {
        width: 100%;
        height: auto!important;
        position: absolute;
        padding-bottom: 3vh;
    }

    .wid-card .body p {
        margin-bottom: 0px!important;
    }
    
    .wid-card h1 {
        top: 28vh;
        font-size: 5vw;
        font-weight: 700;
        margin-left: 30px;
    }
    
    .wid-card img {
        max-width: 100%;
        height: 40vh;
    }
    
    .wid-card h1:before {
      width: 8vw;
      margin-top: 7vw;
    }

    .wid-card.active .body {
        -webkit-animation-name: slide-down;
                animation-name: slide-down;
    }

    .wid-card.active {
        -webkit-animation-name: slide-down;
                animation-name: slide-down;
    }


    @-webkit-keyframes slide-down {
        from {
            top: 100%;
            max-height: 0vh;
        }
        to { 
            top: 100%;
            max-height: 80vh;
            padding-left: 5vw;
            padding-right: 5vw;
        }
    }


    @keyframes slide-down {
        from {
            top: 100%;
            max-height: 0vh;
        }
        to { 
            top: 100%;
            max-height: 80vh;
            padding-left: 5vw;
            padding-right: 5vw;
        }
    }

    .wid-card.active .body p {
        -webkit-animation-name: appear-end-animation;
                animation-name: appear-end-animation;
    }

    .wid-card.active .body h2 {
        -webkit-animation-name: appear-end-animation;
                animation-name: appear-end-animation;
    }

    .wid-card.active .body .partner {
        -webkit-animation-name: appear-end-animation;
                animation-name: appear-end-animation;
    }

}


@media only screen and (min-width: 1200px) {

    .wid-card .body {
        width: 0%;
        height: auto!important;
        min-height: 100%;
        max-height: 100%;
    }

    .wid-card.left.active .body {
        animation-name: slide-right;
        -webkit-animation-name: slide-right;
    }

    .wid-card.active.middle {
        animation-name: slide-mid;
        -webkit-animation-name: slide-mid;
    }

    .wid-card.active.middle .body {
        animation-name: slide-right;
        -webkit-animation-name: slide-right;
    }

    .wid-card.active.right .body {
        animation-name: slide-left;
        -webkit-animation-name: slide-left;
    }

    @-webkit-keyframes slide-right
    {
        from {
            width: 0%;
            left: 100%;
        }
        to { 
            width: 100%;
            left: 100%;
            padding-left: 2vw;
            padding-right: 2vw;
        }
    }

    @keyframes slide-right
    {
        from {
            width: 0%;
            left: 100%;
        }
        to { 
            width: 100%;
            left: 100%;
            padding-left: 2vw;
            padding-right: 2vw;
        }
    }

    @-webkit-keyframes slide-left
    {
        from {
            width: 0%;
            right: 100%;
        }
        to { 
            width: 100%;
            right: 100%;
            padding-left: 2vw;
            padding-right: 2vw;
        }
    }

    @keyframes slide-left
    {
        from {
            width: 0%;
            right: 100%;
        }
        to { 
            width: 100%;
            right: 100%;
            padding-left: 2vw;
            padding-right: 2vw;
        }
    }

    @-webkit-keyframes slide-mid
    {
        from {
            right:0vh;
        }
        to { 
            right:25vh;
        }
    }

    @keyframes slide-mid
    {
        from {
            right:0vh;
        }
        to { 
            right:25vh;
        }
    }

    .wid-card {
        text-align: center;
    }

    .wid-card h1 {
        top: 55vh;
        max-width: 100%;
        font-size: 1.9vw;
        font-weight: 700;
    }

    .wid-card img {
        height: 80vh;
    }

    .wid-card h1:before {
      width: 80px;
      margin-top: 50px;
    }

    .wid-card.active .body p {
        animation-name: appear-end-animation;
        -webkit-animation-name: appear-end-animation;
    }

    .wid-card.active .body h2 {
        animation-name: appear-end-animation;
        -webkit-animation-name: appear-end-animation;
    }

    .wid-card.active .body .partner {
        animation-name: appear-end-animation;
        -webkit-animation-name: appear-end-animation;
    }
}

@keyframes appear-end-animation
{
    0% {
        visibility: hidden;
    }
    90% { 
        visibility: hidden;
    }
    100% { 
        visibility: visible;
    }

}
@-webkit-keyframes appear-end-animation
{
    0% {
        visibility: hidden;
    }
    90% { 
        visibility: hidden;
    }
    100% { 
        visibility: visible;
    }
}

